<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>Distributor Code</label>
            <InputText v-model="item.db_code" required="true" autofocus :class="{ 'p-invalid': errorEdit.distributor_code }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.distributor_code" >{{ errorEdit.distributor_code[0] }}</small >
        </div>
        <div class="field">
            <label>Distributor Name</label>
            <InputText v-model="item.db_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.distributor_name }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.distributor_name" >{{ errorEdit.distributor_name[0] }}</small >
        </div>
        <div class="field">
            <label>City</label>
            <Dropdown  dataKey="city_id" 
                    ref="dcity" v-model="item.city_id" 
                    :loading="loadingDropdownCity" 
                    :options="dataDropdownCity" 
                    :class="{ 'p-invalid': errorEdit.city_id }" 
                    optionLabel="city_name" optionValue="city_id" 
                    placeholder="Choose City" :filter="true" 
                    :showClear="true" @filter="searchDropdownCity($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.city_id" >{{ errorEdit.city_id[0] }}</small>
        </div>
        <div class="field">
            <label>Address</label>
            <InputText v-model="item.db_address" required="true" autofocus :class="{ 'p-invalid': errorEdit.address }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.address" >{{ errorEdit.address[0] }}</small >
        </div>
        <div class="field">
            <label>Phone</label>
            <InputText v-model="item.db_phone" required="true" autofocus :class="{ 'p-invalid': errorEdit.phone }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.phone" >{{ errorEdit.phone[0] }}</small >
        </div>
        <div class="field">
            <label>Email</label>
            <InputText v-model="item.db_email" required="true" autofocus :class="{ 'p-invalid': errorEdit.email }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.email" >{{ errorEdit.email[0] }}</small >
        </div>
        <div class="field">
            <label>Coordinate</label>
            <InputText v-model="item.coordinates" required="true" autofocus :class="{ 'p-invalid': errorEdit.coordinate }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.coordinate" >{{ errorEdit.coordinate[0] }}</small >
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownCity: false,

            // dataDropdown
            dataDropdownCity: null,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.searchDropdownCity('', 'edit', this.item.city_name);
                    }
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // DROPDOWN
        searchDropdownCity(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dcity.filterValue = valueEdit;
                }

                if (purpose == "edit") {
                    this.loadingDropdownCity = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-city',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "edit") {
                        this.dataDropdownCity = res.data.data;
                        this.loadingDropdownCity = false;
                    } else if (purpose == null) {
                        this.dataDropdownCity = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/distributor/update',
                data: {
                    "distributor_code": this.item.db_code,
                    "distributor_name": this.item.db_name,
                    "phone": this.item.db_phone,
                    "email": this.item.db_email,
                    "city_id": this.item.city_id,
                    "address": this.item.db_address,
                    "coordinate": this.item.coordinates,
                    "db_id" : this.item.db_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Update', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Update', life: 3000,});
            });
		},
    }
}
</script>