<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="distributor_code">Distributor Code</label>
                <InputText id="distributor_code" v-model="forms.distributor_code" required="true" autofocus :class="{ 'p-invalid': errorAdd.distributor_code }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.distributor_code" >{{ errorAdd.distributor_code[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="distributor_name">Distributor Name</label>
                <InputText id="distributor_name" v-model="forms.distributor_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.distributor_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.distributor_name" >{{ errorAdd.distributor_name[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>City</label>
                    <Dropdown id="city_id" dataKey="city_id" v-model="forms.city_id" :loading="loadingDropdownCity"
                        :options="dataDropdownCity" :class="{ 'p-invalid': errorAdd.city_id }"
                        optionLabel="city_name" optionValue="city_id" placeholder="Choose City"
                        :filter="true" :showClear="true" @filter="searchDropdownCity($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.city_id">{{
                        errorAdd.city_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="address">Address</label>
                <InputText id="address" v-model="forms.address" required="true" autofocus :class="{ 'p-invalid': errorAdd.address }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.address" >{{ errorAdd.address[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="phone">Phone</label>
                <InputText id="phone" v-model="forms.phone" required="true" autofocus :class="{ 'p-invalid': errorAdd.phone }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.phone" >{{ errorAdd.phone[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="email">Email</label>
                <InputText id="email" v-model="forms.email" required="true" autofocus :class="{ 'p-invalid': errorAdd.email }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.email" >{{ errorAdd.email[0] }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="coordinate">Coordinate</label>
                <InputText id="coordinate" v-model="forms.coordinate" required="true" autofocus :class="{ 'p-invalid': errorAdd.coordinate }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.coordinate" >{{ errorAdd.coordinate[0] }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownCity: false,

            // dataDropdown
            dataDropdownCity: null,

            // addNew
            forms: {
                distributor_code: null,
                distributor_name: null,
                phone: null,
                email: null,
                address: null,
                coordinate: null,
                city_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownCity('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // DROPDOWN
        searchDropdownCity(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.ddistributor.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownCity = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-city',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownCity = res.data.data;
                            this.loadingDropdownCity = false;
                        } else if (purpose == null) {
                            this.dataDropdownCity = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/distributor/create',
                data: {
                    "distributor_code": this.forms.distributor_code,
                    "distributor_name": this.forms.distributor_name,
                    "phone": this.forms.phone,
                    "email": this.forms.email,
                    "city_id": this.forms.city_id,
                    "address": this.forms.address,
                    "coordinate": this.forms.coordinate,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Save', life: 3000,});
            });
        },
        clearForms() {
            this.forms.distributor_code = null;
            this.forms.distributor_name = null;
            this.forms.phone = null;
            this.forms.email = null;
            this.forms.city_id = null;
            this.forms.address = null;
            this.forms.coordinate = null;
        },
    }
}
</script>